import * as React from "react";
import { Helmet } from "react-helmet";

import Theme from "../components/theme";
import Footer from "../components/footer";

// import hero from '../images/hero.jpg';

import "../styles/index.css";
import Hero from "../components/hero";
import CookieConsent from "../components/cookieConsent";
import Buchung from "../components/buchung";

const IndexPage = () => {
  return (
    <Theme>
      <Helmet>
        <title>Angela Hollstein - Coaching - Supervision - Workshops</title>
        <meta name="description" content="Coaching – Supervision – Workshops" />
        <meta
          name="keywords"
          content="supervision, coaching, freiburg, schweiz, deutschland, beratung, frauen"
        />
      </Helmet>
      {/* Hero */}
      <Hero
        title=""
        heading={
          <div className="leading-relaxed">
            Coaching <br />
            Supervision <br />
            Workshops
          </div>
        }
      />

      <div className="flex items-center justify-center z-10 -mt-10">
        <div className="w-4/5 md:w-2/3 md:mt-20">
          <div className="text-center">
            <div className="mb-2">„Ein Ziel ohne Plan ist nur ein Wunsch“</div>
            <div className="mb-2 font-bold">Antoine de Saint-Exupéry</div>
          </div>
        </div>
      </div>

      <section className="py-6">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-lg mx-auto mt-8 overflow-hidden bg-white bg-opacity-70 rounded-lg md:max-w-4xl">
            <div className="relative p-12 font-medium leading-8">
              Ich bin Angela Hollstein, systemische Coach und Supervisorin.
              <br />
              Manchmal braucht es nur einen Blick von außen, eine neue Struktur
              oder eine neue Vision, um zur individuellen Lösung zu gelangen.
              Aber es braucht immer einen Anfang und einen vertrauensvollen
              Ausblick. Ob privates Anliegen oder Business Anfrage – mit einem
              besonderen Fokus und methodischem Vorgehen bietet ich Ihnen
              hilfreiche Klarheit, besondere Impulse und den individuellen Weg
              zum zugeschnittenen Ziel.
            </div>
          </div>
        </div>
      </section>

      <Buchung />

      {/* Supervision vs Coaching */}
      {/* <h2 className="text-5xl text-gray-900 leading-tight px-6 font-bold mt-20 text-center">
        Erfahren Sie mehr
      </h2>
      <div className="md:flex text-xl">
        <div 
          className="md:w-1/2 m-3 md:m-8 p-6 py-14"
        >
          <h2 className="text-center text-2xl font-bold">
            Coaching für Frauen
          </h2>
          <div className="flex justify-center">
            <p className="text-base mt-6 md:w-2/3">
              Sie sind zerrissen zwischen den Ansprüchen von Familie und Beruf?<br />
              Sie suchen Wege, wieder selbstwirksam und selbstbestimmt zu handeln?<br />
              Ich unterstütze Sie auf Ihrem individuellen Weg mit systemischem Online-Einzelcoaching. Gemeinsam werden wir Ihre Herausforderungen im Alltag beleuchten und Ihre Ressourcen aktivieren, damit Sie Ihr großes Potential an Handlungsalternativen in Ihrem täglichen Leben nutzen können.
            </p>
          </div>
          <div className="flex justify-center">
            <Link to="/coaching" className="text-center">
              <p className="text-base p-3 font-sans bg-light-blue mt-6">
                Mehr erfahren <ChevronRight size={18} className="inline-block" />
              </p>
            </Link>
          </div>
        </div>

        <div 
          className="md:w-1/2 m-3 md:m-8 p-6 py-14"
        >
          <h2 className="text-center text-2xl font-bold">
            Supervision für Frauenteams
          </h2>
          <div className="flex justify-center">
            <p className="text-base mt-6 md:w-2/3">
            Ich unterstütze Frauenteams und Teams mit hohem Frauenanteil mithilfe systemischer Supervision in ihrem professionellen Handeln.<br />
            Dabei berücksichtigen wir die spezifischen Herausforderungen dieser Teams, wie beispielsweise die Vereinbarkeit von Familie und Beruf oder ein hoher Anteil an Teilzeitkräften.<br />
            Durch Fall- oder Teamsupervision kommen Ihre Teams wieder ins selbstwirksame Handeln, dabei ist meine Haltung als Supervisorin pragmatisch und lösungsorientiert.

            </p>
          </div>
          <div className="flex justify-center">
            <Link to="/supervision" className="text-center">
              <p className="text-base p-3 font-sans bg-light-blue mt-6">
                Mehr erfahren <ChevronRight size={18} className="inline-block" />
              </p>
            </Link>
          </div>
        </div>
      </div> */}

      <CookieConsent />

      <Footer />
    </Theme>
  );
};

export default IndexPage;
